.modal-content-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
