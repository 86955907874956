form.home-form {
    max-width: auto;

    .calendar {
        .form-control {
            input  {
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 11px;
            top: 6px;
            z-index: 10;
            font-size: 1.5em;
        }
    }
}

thead th span.ascending::after {
    content: '↓';
    margin-left:.25rem;
}

thead th span.descending::after {
    content: '↑';
    margin-left:.25rem;
}

thead th.sortable::before {
    content: '↓↑';
    color: #ddd;
    right: 20px;
    margin-right:.25rem;
}

thead th.sortable span{
    cursor: pointer;
}
