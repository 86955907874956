.paginate-content {
    display: flex;
    justify-content: space-between;
    padding: 0px 70px;
    .select-items { margin-left: 10px; }
    .select-items, .select-pages {
        width: 60px;
        height: 40px;
        border-radius: 5px;
        border: solid 1px #4b5056;
    }
    .select-pages {
        margin: 0px 15px;
    }
    .number-page {
        display: flex;
        align-items: center;
        svg { cursor: pointer; }
    }
    @media (max-width: 500px){  
        padding: 0px;
        flex-direction: column-reverse;
        align-items: center;
        .number-page { margin-bottom: 10px; }
        .select-items { margin: 0; }
        .items {
            display: flex;
            flex-direction: column;
            align-items: center; 
        }
    }
}