form.home-form {
    max-width: auto;

    .calendar {
        .form-control {
            input  {
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 11px;
            top: 6px;
            z-index: 10;
            font-size: 1.5em;
        }
    }
}
