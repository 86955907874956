.icon-calendar-conversions {
    cursor: pointer;
    position: absolute;
    right: 11px;
    top: 38px;
    z-index: 10;
    font-size: 1.5em;
}

.campaign_chips{
    padding: 5px;
    margin: 2.5px;
    font-size: 14px;
}