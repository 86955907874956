@import "~bootstrap/scss/bootstrap";
@import '~react-datepicker/src/stylesheets/datepicker.scss';

html, body {
    height: 100%;
}

#root{
    width:100%;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

a:hover{
    text-decoration: none;
}
.react-datepicker{
    display: inline-flex;
}

.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 8;
  }
}

.handed{
    cursor: pointer;
}
