form.home-form {
    max-width: auto;

    .calendar {
        .form-control {
            input  {
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 6px;
            z-index: 10;
            font-size: 1.5em;
        }
    }
}

.infoHr{
    width:100%;
}

.datepickercustom{
    padding:16px;
}

.datepickercustom input#calendar_date, .datepickercustom input#calendar_date1{
    display:none;
}
